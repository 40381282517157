import gql from 'graphql-tag'


const docConfigResponse = `
  id type docType name options
`

export const LIST_DOC_CONFIG_PRINT_LEDGER_PDF = (templateType) => gql`query LIST_DOC_CONFIG ($docType: String!, $q: FilterInput) {
  items: list${templateType}DocConfigPrintLedgerPdf (docType: $docType, q: $q) {${docConfigResponse}}
}`

export const CREATE_DOC_CONFIG_PRINT_LEDGER_PDF = (templateType) => gql`mutation CREATE_DOC_CONFIG_PRINT_LEDGER_PDF ($docType: String!, $input: ${templateType}DocConfigPrintLedgerPdfInput!) {
  item: create${templateType}DocConfigPrintLedgerPdf (docType: $docType, input: $input) {${docConfigResponse}}
}`

export const UPDATE_DOC_CONFIG_PRINT_LEDGER_PDF = (templateType) => gql`mutation UPDATE_DOC_CONFIG_PRINT_LEDGER_PDF ($docType: String!, $docConfigId: Int!, $input: ${templateType}DocConfigPrintLedgerPdfInput!) {
  item: update${templateType}DocConfigPrintLedgerPdf (docType: $docType, docConfigId: $docConfigId, input: $input) {${docConfigResponse}}
}`

export const DESTROY_DOC_CONFIG_PRINT_LEDGER_PDF = (templateType) => gql`mutation DESTROY_DOC_CONFIG_PRINT_LEDGER_PDF ($docType: String!, $docConfigId: Int!) {
  item: destroy${templateType}DocConfig (docType: $docType, docConfigId: $docConfigId) {id}
}`
