<template>
  <div class="">
    <div class="card" v-if="!toggle && item">
      <div class="card-body">
        <span class="card-text">
          <span
            class="mx-1 pointer float-right"
            @click="toggle = !toggle">
            <fa icon="pencil-alt" class="text-warning"></fa>
          </span>
          <div class="">
            {{item.name}}
          </div>
          <div class="text-info" v-if="item.options && item.options.title">
            <small>ชื่อเอกสาร: {{item.options.title}}</small>
          </div>
          <div class="text-primary" v-if="item.options && item.options.contact">
            <small>ชื่อบริษัท: {{item.options.contact}}</small>
          </div>
          <div class="pre-line" v-if="item.options && item.options.address">
            <small>ที่อยู่บริษัท: {{item.options.address}}</small>
          </div>
        </span>
      </div>
    </div>

    <div class="card" v-if="toggle">
      <div class="card-body" v-if="toggle">
        <div class="card-text">
          <div class="form-row">
            <sgv-input-text
              class="col-12"
              label="ชื่อ"
              v-model="formData.name"
              :validations="[
                {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
              ]">
            </sgv-input-text>

            <sgv-input-text
              class="col-12"
              label="ชื่อเอกสาร"
              v-model="formData.options.title"
              :validations="[
                {text: 'required!', value: $v.formData.options.title.$dirty && !$v.formData.options.title.required}
              ]">
            </sgv-input-text>

            <sgv-input-text
              class="col-12"
              label="ชื่อบริษัท"
              v-model="formData.options.contact"
              :validations="[
                {text: 'required!', value: $v.formData.options.contact.$dirty && !$v.formData.options.contact.required}
              ]">
            </sgv-input-text>

            <sgv-input-textarea
              class="col-12"
              label="ที่อยู่บริษัท"
              v-model="formData.options.address"
              :validations="[
                {text: 'required!', value: $v.formData.options.address.$dirty && !$v.formData.options.address.required}
              ]">
            </sgv-input-textarea>
          </div>

          <button
            v-if="isForm"
            type="button"
            class="btn btn-success"
            @click="createData">
            เพิ่ม
          </button>

          <button
            v-if="!isForm"
            type="button"
            class="btn btn-warning"
            @click="updateData">
            แก้ไข
          </button>

          <button
            v-if="toggle"
            type="button"
            class="btn btn-primary ml-2"
            @click="cancelData">
            ยกเลิก
          </button>

          <button
            v-if="!isForm"
            type="button"
            class="btn btn-danger ml-2 float-right"
            @click="destroyData">
            ลบ
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  CREATE_DOC_CONFIG_PRINT_LEDGER_PDF,
  UPDATE_DOC_CONFIG_PRINT_LEDGER_PDF,
  DESTROY_DOC_CONFIG_PRINT_LEDGER_PDF,
} from './graph/docConfig.printLedgerPdf'

export default {
  props: {
    templateType: {
      type: String,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: false
    },
    isForm: {
      type: Boolean,
      required: false
    },
  },
  data () {
    return {
      toggle: false,
      formData: {
        name: '',
        options: {
          title: '',
          contact: '',
          address: '',
        }
      }
    }
  },
  validations: {
    formData: {
      name: {required},
      options: {
        title: {required},
        contact: {required},
        address: {required},
      }
    }
  },
  methods: {
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        this.formData[key] = v[key]
      })
    },
    serializeInput (v) {
      return {
        name: v.name,
        options: v.options
      }
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_DOC_CONFIG_PRINT_LEDGER_PDF(this.templateType),
        variables: {
          docType: this.docType,
          input: this.serializeInput(this.formData)
        }
      })
      .then(res => {
        this.$emit('created', res.data.item)
        this.toggle = false
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: UPDATE_DOC_CONFIG_PRINT_LEDGER_PDF(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.item.id,
          input: this.serializeInput(this.formData)
        }
      })
      .then(res => {
        this.$emit('updated', res.data.item)
        this.toggle = false
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DOC_CONFIG_PRINT_LEDGER_PDF(this.templateType),
        variables: {
          docType: this.docType,
          docConfigId: this.item.id
        }
      })
      .then(res => {
        this.$emit('destroyed', res.data.item)
        this.toggle = false
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    cancelData () {
      this.toggle = false
      this.$emit('cancel', true)
    }
  },
  watch: {
    toggle (value) {
      if (value && !this.isForm) {
        this.$nextTick(() => {
          this.formData.name = this.item.name
          this.formData.options = this.item.options
        })
      } if (value && this.isForm) {
        this.$nextTick(() => {

        })
      } else {
        this.$v.formData.$reset()
        this.formData.name = ''
        this.formData.options = {
          title: '',
          contact: '',
          address: '',
        }
        this.$emit('update:isForm', false)
      }
    },
    isForm (value) {
      if (value) {
        this.toggle = true
      }
    }
  },
  created () {
    if (this.item) {
      this.setFormData(this.item)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
